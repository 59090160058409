import { createGetKcContext } from 'keycloakify/login'

export type KcContextExtension = {
    pageId: 'hidpd-select-idp.ftl'
    hidpd: {
        displayInfo: boolean
        providers: {
            displayName: string
            providerId: string
            loginUrl: string
            iconClasses: string
            alias: string
        }[]
    }
}

export const { getKcContext } = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            pageId: 'login.ftl',
            locale: {
                currentLanguageTag: 'ru',
            },
        },
        {
            pageId: 'login-update-password.ftl',
            locale: {
                currentLanguageTag: 'ru',
            },
        },
        {
            pageId: 'login-username.ftl',
            locale: {
                currentLanguageTag: 'ru',
            },
            auth: {
                showTryAnotherWayLink: true,
            },
        },
        {
            pageId: 'login-reset-password.ftl',
            locale: {
                currentLanguageTag: 'ru',
            },
            realm: {
                loginWithEmailAllowed: true,
                registrationEmailAsUsername: true,
            },
        },
        {
            pageId: 'select-authenticator.ftl',
            locale: {
                currentLanguageTag: 'ru',
            },
        },
        {
            pageId: 'hidpd-select-idp.ftl',
            locale: {
                currentLanguageTag: 'ru',
            },
            hidpd: {
                displayInfo: true,
                providers: [
                    {
                        displayName: 'oidc',
                        providerId: 'oidc',
                        loginUrl:
                            '/realms/cdj/broker/oidc/login?client_id=cdj-client&tab_id=Y082FJyNdN4&session_code=P_dH5mv2M5YycHM48p_h1O3NKJ4UU27dZLMNbtRUMfU',
                        iconClasses: '',
                        alias: 'oidc',
                    },
                    {
                        displayName: 'microsoft',
                        providerId: 'oidc',
                        loginUrl:
                            '/realms/cdj/broker/microsoft/login?client_id=cdj-client&tab_id=Y082FJyNdN4&session_code=P_dH5mv2M5YycHM48p_h1O3NKJ4UU27dZLMNbtRUMfU',
                        iconClasses: 'fa fa-windows',
                        alias: 'microsoft',
                    },
                ],
            },
        },
    ],
})

export const { kcContext } = getKcContext({
    // Uncomment to test the login page for development.
    // mockPageId: 'login.ftl',
})

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>
